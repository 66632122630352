<!--
 * @Author: wangwei
 * @Date: 2020-12-29 15:56:16
 * @LastEditTime: 2023-01-18 11:26:19
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: 订单支付页面
 * @FilePath: /java-pc/src/views/buy/pay.vue
-->
<template>
    <div id="pay">
        <div class="top_info_header">
            <div class="top_logo">
                <div :style="`background-image:url(${configInfo.main_site_logo})`" :onerror="defaultImg"></div>
            </div>
            <div class="top_info_progress flex_row_end_center">
                <div class="progress_item" v-if="ifcart == 'true'">
                    <div class="progress flex_row_center_center">
                        <span class="active">1</span>
                        <div class="progress_line">
                            <div :class="{ content: true, active: true }"></div>
                        </div>
                    </div>
                    <p>购物车</p>
                </div>

                <div class="progress_item">
                    <div class="progress flex_row_center_center">
                        <span class="active">{{ ifcart == "true" ? 2 : 1 }}</span>
                        <div class="progress_line">
                            <div :class="{ content: true, active: true, current: false }"></div>
                        </div>
                    </div>
                    <p style="margin-left: -11px">确认订单</p>
                </div>

                <div class="progress_item">
                    <div class="progress flex_row_center_center">
                        <span class="active">{{ ifcart == "true" ? 3 : 2 }}</span>
                        <div class="progress_line">
                            <div :class="{ content: true, active: payComplete }"></div>
                        </div>
                    </div>
                    <p>付款</p>
                </div>

                <div class="progress_item" style="margin-left: -24px">
                    <div class="progress flex_row_end_center">
                        <span :class="{ active: payComplete }">{{ ifcart == "true" ? 4 : 3 }}</span>
                    </div>
                    <p>支付成功</p>
                </div>
            </div>
        </div>
        <div class="top_info" v-if="route.query.payFrom != 2">
            <div class="top_info_text flex_row_between_between">
                <span>订单提交成功，请您尽快付款！</span>
                <div class="price">
                    应付金额
                    <span>{{ pay_info.data.needPay }}</span> 元
                </div>
            </div>
            <div class="top_info_text flex_row_between_between">
                <span>
                    请您在提交订单后
                    <span>24小时内</span>完成支付，否则订单会自动取消
                </span>
                <div class="show_detail pointer" @click="showDetail">
                    {{ show_detail ? "收起详情" : "展开详情" }}
                </div>
            </div>
            <div class="receive_info">
                <p v-if="show_detail && pay_info.data.receiveAddress && pay_info.data.receiverName">
                    收货地址：{{ pay_info.data.receiveAddress }} 收货人：{{ pay_info.data.receiverName }}
                    {{ pay_info.data.receiverMobile }}
                </p>
                <p v-if="show_detail">商品名称：{{ buy_name_list }}</p>
            </div>
        </div>
        <div class="top_info" v-if="route.query.payFrom == 2">
            <div class="top_info_text flex_row_between_between">
                <span>账单已出，请您尽快付款！</span>
                <div class="price">
                    应付金额
                    <span>{{ pay_info.data.needPay }}</span> 元
                </div>
            </div>
            <div class="top_info_text flex_row_between_between accountPayment">
                <p>账期时间：{{ pay_info.data.accountPeriodDate }}</p>
            </div>
        </div>
        <div class="bg">
            <div class="pay_method" v-if="hasPayMethod && true">
                <div v-if="!show_wx_pay && isgoBnak < 2">
                    <div class="balance" v-if="balance_list.data.length > 0">
                        <div class="balance_title">
                            <img class="logo1" :src="balance" alt />
                            <span>余额支付</span>
                        </div>
                        <div class="balance_info">
                            <i
                                :class="{
                                    iconfont: true,
                                    'icon-yuanquan1': pay_info.data.balanceAvailable >= pay_info.data.needPay,
                                    'icon-duihao1': current_pay_method.data.payMethod == balance_list.data[0].payMethod,
                                    'icon-jinzhi1': pay_info.data.balanceAvailable < pay_info.data.needPay
                                }"
                                @click="
                                    pay_info.data.balanceAvailable >= pay_info.data.needPay
                                        ? changePayMethod(balance_list.data[0])
                                        : ''
                                "
                            ></i>
                            <!-- <i class="iconfont i"></i> -->
                            <span
                                class="weight big_size"
                                @click="
                                    pay_info.data.balanceAvailable >= pay_info.data.needPay
                                        ? changePayMethod(balance_list.data[0])
                                        : ''
                                "
                                >使用余额支付</span
                            >
                            <span class="avai">
                                （可用余额：
                                <span class="weight">{{ pay_info.data.balanceAvailable }}</span
                                >元，目前需要在线支付： <span class="weight">{{ pay_info.data.needPay }}</span
                                >元）余额不足？
                                <span class="weight pointer" @click="goRecharge" style="color: #168ed8">马上充值</span>
                            </span>
                        </div>
                        <div
                            class="password"
                            v-if="current_pay_method.data.payMethod == balance_list.data[0].payMethod"
                        >
                            <el-input class="password_input" placeholder="支付密码" v-model="password" show-password>
                            </el-input>
                            <p v-if="!memberInfo.hasPayPassword">
                                <span style="color: #ffb33a"
                                    ><i class="el-icon-warning"></i>未设置支付密码，马上去设置</span
                                >
                                <router-link class="set_password pointer" :to="'/member/pwd/pay'">设置密码</router-link>
                            </p>
                            <p v-else>
                                <span class="forget_password pointer" @click="forgetPassword">忘记密码?</span>
                            </p>
                        </div>
                        <div style="height: 2px; background: #eff0f1"></div>
                    </div>
                    <div class="balance other_pay_method" v-if="other_pay_list.data.length > 0">
                        <div class="balance_title">
                            <img class="logo2" :src="otherPay" alt />
                            <span>其他支付</span>
                        </div>
                        <div class="invoice_con flex" v-if="memberInfo.memberUserType != 0">
                            <div
                                class="no_select_invoice flex_row_center_center"
                                :class="{ activeLt: index != 0 }"
                                v-for="(item, index) in paymentList.data"
                                :key="index"
                                @click="handlePayment(index)"
                            >
                                <!-- <img :src="selected" v-if="activeIndex==index" alt />
                  1111              <img :src="not_select" v-else alt /> -->

                                <i
                                    :class="{
                                        iconfont: true,
                                        'icon-yuanquan1': activeIndex != index && item.disabled,
                                        'icon-duihao1': activeIndex == index
                                    }"
                                ></i>
                                <span v-if="item.disabled">{{ item.label }}</span>
                            </div>
                        </div>
                        <!-- 网银支付 -->
                        <div
                            class="balance_info flex bankList"
                            v-if="memberInfo.memberUserType != 0 && activeIndex == 0"
                        >
                            <div
                                :class="{
                                    other_pay_item: true,
                                    flex_row_around_center: true,
                                    wechat: true,
                                    other_pay_items: true,
                                    borderClass: ischecked == item.code
                                }"
                                v-for="(item, index) in bankList"
                                :key="index"
                                @click="changeBnak(item)"
                            >
                                <i
                                    :class="{
                                        iconfont: true,
                                        'icon-yuanquan1': ischecked != item.code,
                                        'icon-duihao1': ischecked == item.code
                                    }"
                                ></i>
                                <!-- <img :src="'../../assets/bank/'+item.code+'.png'" alt /> -->
                                <img :src="item.img" alt="" />
                                <span>{{ item.name }}</span>
                            </div>
                            <!-- <div class="bankInput">
                                <span>请输入银行卡号</span>
                                <input class="bankInput_con" v-model="bankNum" />
                            </div> -->
                            <!-- !memberInfo.hasPayPassword&& -->
                        </div>
                        <!-- 线下承兑 -->
                        <div class="chengdui_box" v-if="activeIndex == 1">
                            <div class="chengdui_title">收款账户信息</div>
                            <div class="tx">收款公司名称：内蒙古大石供应链管理有限公司</div>
                            <div class="tx2">收款人账号:59080078801100000522</div>
                            <div class="tx2">收款人银行信息：浦发银行呼和浩特成吉思汗大街支行</div>
                        </div>
                        <!-- 订单支付 -->
                        <div class="order_box" v-if="activeIndex == 2">
                            <div class="tips">
                                订单支付支持以下银行账户交通，浦发，民生，兴业，平安，浙商。选择之后可直接登录已选择企业付款账户网银进行支付。
                            </div>
                            <div class="orderPay_info">
                                <div>
                                    <span class="tx">企业名称：</span>
                                    <el-input v-model="firmName" placeholder="请输入企业名称" disabled />
                                </div>
                                <div>
                                    <span class="tx">银行名称：</span>
                                    <el-select
                                        v-model="bankNameVal"
                                        class="m-2"
                                        placeholder="请选择银行类型"
                                        style="width: 356px"
                                    >
                                        <el-option
                                            v-for="item in options.data"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value"
                                        />
                                    </el-select>
                                </div>
                                <div>
                                    <span class="tx">付款账号：</span>
                                    <el-input v-model="paymentAccount" placeholder="请输入付款账号" />
                                </div>
                                <div>
                                    <span class="tx">企业账户类型：</span>
                                    <el-select
                                        v-model="firmAccountVal"
                                        class="m-2"
                                        placeholder="请选择企业账户类型"
                                        style="width: 356px"
                                    >
                                        <el-option
                                            v-for="item in options.data"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value"
                                        />
                                    </el-select>
                                </div>
                            </div>
                        </div>
                        <p v-if="!memberInfo.hasPayPassword && ischecked" style="margin-left: 66px">
                            <span style="color: #ffb33a"
                                ><i class="el-icon-warning"></i>未设置支付密码，马上去设置</span
                            >
                            <router-link class="set_password pointer" :to="'/member/pwd/pay'">设置密码</router-link>
                        </p>
                        <p v-else style="margin-left: 66px; width: 10px; height: 20px"></p>
                        <div class="balance_info flex" v-if="isgoBnak < 2 && memberInfo.memberUserType == 0">
                            <i
                                @click="changePayMethod('yhk')"
                                :class="{
                                    iconfont: true,
                                    iconfontss: true,
                                    'icon-yuanquan1': current_pay_method.data.payMethod != 'yhk',
                                    'icon-duihao1': current_pay_method.data.payMethod == 'yhk'
                                }"
                            ></i>
                            <div
                                :class="{
                                    other_pay_item: true,
                                    flex_row_around_center: true,
                                    wechat: true
                                }"
                                @click="changePayMethod('yhk')"
                            >
                                <!-- <img src="../../assets/fx_jiesuan.png" alt /> -->
                                <span>银行卡支付</span>
                            </div>
                            <p
                                v-if="!memberInfo.hasPayPassword && current_pay_method.data.payMethod == 'yhk'"
                                style="margin-left: 10px; margin-top: 9px"
                            >
                                <span style="color: #ffb33a"
                                    ><i class="el-icon-warning"></i>未设置支付密码，马上去设置</span
                                >
                                <router-link class="set_password pointer" :to="'/member/pwd/pay'">设置密码</router-link>
                            </p>
                        </div>
                    </div>
                    <div
                        :class="{ pay, isPay: !payFlag }"
                        @click="pay"
                        style="cursor: pointer"
                        v-if="isgoBnak < 2 && !isChengdui"
                    >
                        立即支付
                    </div>
                    <div class="pay" @click="handleChengdui" style="cursor: pointer" v-if="isChengdui">
                        填写支付凭证
                    </div>
                </div>
                <div class="wx_pay_con" v-if="show_wx_pay">
                    <p class="title" @click="hideWxPay">
                        <i class="iconfont icon-ziyuan2"></i>
                        <span>选择其它支付方式</span>
                    </p>
                    <div class="line"></div>
                    <div class="qr flex_row_around_center">
                        <div class="left flex_column_start_center">
                            <img :src="wxPayQrImg" />
                            <p>
                                使用
                                <span>微信支付</span>APP
                            </p>
                            <p>扫码完成支付</p>
                            <div class="refresh" @click="refreshWxQrcode">刷新</div>
                        </div>
                        <img class="wx_png" :src="wx_pay_tip" />
                    </div>
                </div>
            </div>
            <!-- 签署协议start -->

            <div class="pay_method" v-if="hasPayMethod && isgoBnak">
                <p class="title" @click="hideWxPays">
                    <i class="iconfont icon-ziyuan2"></i>
                    <span>选择其它支付方式</span>
                </p>
                <div class="line"></div>
                <div class="bank_bottom_Form">
                    <!-- 绑定银行卡 start -->
                    <el-form :model="bankForm" label-width="128px" v-if="isgoBnak == 4" style="margin-top: 50px">
                        <!-- <div class="otherPay"> <img />选择其他支付方式 </div> -->
                        <!-- <UnBind v-if="true"></UnBind  > -->
                        <el-form-item label="支付密码">
                            <el-col style="float: none; width: 295px">
                                <el-input v-model="payPwd" type="password" placeholder="请输入支付密码" />
                            </el-col>
                        </el-form-item>
                        <el-form-item label="短信验证码:" style="margin-top: 10px">
                            <el-col style="float: none; width: 295px">
                                <el-input v-model="testnum" placeholder="请输入短信验证码" width="120px" />
                                <div class="timeOut" style="cursor: pointer" @click="getTestNumss(item)">
                                    {{ descNum1 ? descNum1 + "s后获取" : "获取验证码" }}
                                </div>
                                <!-- <div class="timeOut">120s后获取</div> -->
                            </el-col>
                        </el-form-item>
                        <el-form-item label="获取验证码手机号:" style="margin-top: 10px">
                            <el-col style="float: none">
                                <div>{{ memberInfo.memberMobile }}</div>
                            </el-col>
                        </el-form-item>
                        <div class="next">
                            <div @click="payNow">立即支付</div>
                        </div>
                    </el-form>
                    <!-- 绑定银行卡 end -->
                    <!-- 打款验证 start -->
                    <el-form :model="bankForm" label-width="120px" v-if="isgoBnak == 3">
                        <!-- <div class="otherPay"> <img />选择其他支付方式 </div> -->
                        <el-form-item label="付款人账号:">
                            <el-col :span="7" style="float: none">
                                <el-input v-model="bankForm.payerAcctNo" placeholder="请输入付款人账号" width="120px" />
                            </el-col>
                        </el-form-item>
                        <el-form-item label="付款户名">
                            <el-col :span="7" style="float: none">
                                <el-input v-model="bankForm.payerAcctName" placeholder="请输入付款户名" />
                            </el-col>
                        </el-form-item>
                        <el-form-item label="证件类型:">
                            <el-col :span="7" style="float: none">
                                <el-select v-model="bankForm.payerGlobalType" placeholder="请选择证件类型">
                                    <el-option label="身份证" value="01" />
                                    <el-option label="港澳回乡证" value="03" />
                                    <el-option label="台胞证" value="05" />
                                    <el-option label="中国护照" value="04" />
                                    <el-option label="外国护照" value="19" />
                                </el-select>
                            </el-col>
                        </el-form-item>

                        <el-form-item label="证件号码">
                            <el-col :span="7" style="float: none">
                                <el-input v-model="bankForm.payerGlobalId" placeholder="请输入证件号码" />
                            </el-col>
                        </el-form-item>
                        <el-form-item label="预留手机号">
                            <el-col :span="7" style="float: none">
                                <el-input v-model="bankForm.payerReserverMobile" placeholder="请输入手机号" />
                            </el-col>
                        </el-form-item>
                        <el-form-item label="短信验证码:">
                            <el-col :span="7" style="float: none">
                                <el-input v-model="messageCheckCode" placeholder="请输入短信验证码" width="120px" />
                                <!-- <div class="timeOut" @click="sendMsg">获取验证码</div> -->
                                <div class="timeOut" style="cursor: pointer" @click="getTestNum">
                                    {{ descNum ? descNum + "s后获取" : "获取验证码" }}
                                </div>
                                <!-- <div class="timeOut">120s后获取</div> -->
                            </el-col>
                        </el-form-item>
                        <div class="next">
                            <div @click="bindCard">立即申请</div>
                        </div>
                    </el-form>
                    <!-- 打款验证 end -->
                    <div v-if="isgoBnak == 2">
                        <div class="bank_content">
                            <!-- <div class="bank_content_top">我的银行卡</div> -->
                            <div class="card_list">
                                <div
                                    class="item"
                                    v-for="(item, index) in bankListSelf"
                                    @click="checkBank(item)"
                                    :key="index"
                                >
                                    <div class="bank_content_bottom">
                                        <div>{{ item.firmBankName }}</div>
                                        <div>{{ item.firmBankAccount }}</div>
                                    </div>
                                    <!--{{bankFlag == item.cardId}}-->
                                    <div :class="[bankFlag == item.id ? 'unbinding' : 'unbinding2']"></div>
                                </div>
                            </div>
                            <div class="flex_row_start_center" style="margin-top: 20px">
                                <input
                                    type="number"
                                    name=""
                                    id=""
                                    class="itemInput"
                                    v-model="bankMessage"
                                    placeholder="请输入短信验证码"
                                    v-if="bankinfo.data.isBind == 2"
                                />
                                <!-- <div class="timeOut2" @click="getTestNums(bankinfo.data)" v-if="bankinfo.data.isBind == 2">{{ isFlag == true ? descNum : '获取验证码' }}</div> -->
                                <div
                                    class="timeOut2"
                                    style="cursor: pointer"
                                    @click="getTestNums(bankinfo.data)"
                                    v-if="bankinfo.data.isBind == 2"
                                >
                                    {{ descNum ? descNum + "s后获取" : "获取验证码" }}
                                </div>
                            </div>
                            <div
                                style="margin-top: 10px; color: #999999; display: flex; align-items: center"
                                v-if="bankinfo.data.isBind == 2"
                            >
                                <img :src="tipImg" alt="" />
                                <span style="margin-left: 4px">首次使用此银行卡充值余额，需先验证激活</span>
                            </div>
                            <div class="activation" style="cursor: pointer" @click="activationCard">
                                {{ bankinfo.data.isBind == 2 ? "立即激活" : "立即支付" }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 签署协议end -->
            <!-- <div class="no_payMethod" v-else>
                <img src="@/assets/member/member_empty.png" alt="">
                <p>暂无可用的支付方式，平台正在紧急处理中～</p>
            </div> -->
            <div class="chengdui-dialog">
                <el-dialog v-model="chengduiVisible" title="填写承兑凭证" width="500px">
                    <div class="cd-box">
                        <div class="cd-item" v-for="(item, index) in cdArr.data" :key="index">
                            <div>
                                <div>
                                    <span>票据号码：</span>
                                    <el-input
                                        v-model="item.billCode"
                                        maxlength="30"
                                        placeholder="请输入票据号码"
                                        autocomplete="off"
                                    />
                                </div>
                                <div class="line">
                                    <span>票据金额：</span>
                                    <el-input
                                        v-model="item.billAmount"
                                        oninput="value=value.match(/\d+\.?\d{0,2}/)"
                                        placeholder="请输入票据金额"
                                        autocomplete="off"
                                    />
                                </div>
                            </div>
                            <!-- <i class="el-icon-delete" @click="handleDelCd(index)"></i> -->
                            <el-button style="margin-left: 10px" type="danger" plain @click="handleDelCd(index)"
                                >删除</el-button
                            >
                        </div>
                    </div>
                    <div class="addBtn">
                        <el-button
                            type="primary"
                            plain
                            style="width: 297px; color: white; background-color: #03499e"
                            @click="handleAddCd"
                            >+ 新增</el-button
                        >
                    </div>
                    <template #footer>
                        <span class="tx">请仔细核对信息是否无误 </span>
                        <span class="dialog-footer">
                            <el-button @click="chengduiVisible = false">取消</el-button>
                            <el-button style="color: white; background-color: #03499e" @click="pay">确认支付</el-button>
                        </span>
                    </template>
                </el-dialog>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive, getCurrentInstance, ref, onMounted, onUnmounted } from "vue";
import { ElMessage, ElInput } from "element-plus";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import UnBind from "./unbind.vue";
import { Encrypt, Decrypt } from "@/utils/common.js";
export default {
    name: "Pay",
    components: {
        ElInput,
        UnBind
    },
    setup() {
        // 签署协议
        const bankForm = ref({
            // messageCheckCode: '', //短信验证码
            payerAcctName: "", //付款用户名
            payerAcctNo: "", //付款人账号
            payerGlobalId: "", //证件号码
            payerGlobalType: "", //证件类型
            payerReserverMobile: ""
            // resource: '',
            // desc: '',
        });
        const messageCheckCode = ref("");
        let isBinding = ref(true);
        // 签署协议end
        const ifcart = ref("");
        const balance = require("../../assets/buy/balance.png");
        const otherPay = require("../../assets/buy/otherPay.png");
        const ali = require("../../assets/buy/ali.png");
        const wechat = require("../../assets/buy/wechat.png");
        const wx_pay_tip = require("../../assets/buy/wx_pay_tip.png");
        const tipImg = require("../../assets/order/tip.png");
        const route = useRoute();
        const router = useRouter();
        const store = useStore();
        const { proxy } = getCurrentInstance();
        const pay_method = reactive({ data: [] });
        const pay_info = reactive({ data: {} });
        const paySn = ref("");
        const current_pay_method = reactive({ data: {} });
        const balance_list = reactive({ data: [] });
        const other_pay_list = reactive({ data: [] });
        const buy_name_list = ref("");
        const password = ref("");
        const show_wx_pay = ref(false);
        const wxPayQrImg = ref(""); //微信支付二维码
        const timer = ref(0); //定时器
        const show_detail = ref(true);
        const hasPayMethod = ref(true);
        const hasFlag = ref(false);
        const configInfo = ref(store.state.configInfo);
        const memberInfo = ref(store.state.memberInfo);
        const defaultImg = ref('this.src="' + require("../../assets/common_top_logo.png") + '"');
        const not_select = require("../../assets/buy/not_select.png");
        const selected = require("../../assets/buy/selected.png");
        const isgoBnak = ref(null); //判断是否去绑卡页面
        const bankName = ref(""); //银行名称
        const chengduiVisible = ref(false); //承兑支付弹窗
        const isChengdui = ref(false); //判断是否承兑支付
        const cdArr = reactive({
            data: [
                //承兑支付数组
                { billCode: null, billAmount: null }
            ]
        });
        const paymentList = reactive({
            data: [
                { label: "网银支付", value: 1, disabled: false },
                { label: "承兑支付", value: 2, disabled: false },
                { label: "订单支付", value: 3, disabled: false },
                { label: "线下结算", value: 4, disabled: false }
            ]
        });
        const payData = reactive({ data: {} });
        const activeIndex = ref(0);
        const firmName = ref("多蒙德冶金化工有限公司");
        const bankNameVal = ref("");
        const paymentAccount = ref("");
        const firmAccountVal = ref("");
        const options = reactive({
            data: [
                {
                    value: "Option1",
                    label: "Option1"
                },
                {
                    value: "Option2",
                    label: "Option2"
                },
                {
                    value: "Option3",
                    label: "Option3"
                },
                {
                    value: "Option4",
                    label: "Option4"
                },
                {
                    value: "Option5",
                    label: "Option5"
                }
            ]
        });
        console.log(memberInfo.value);

        //选择付款方式
        const handlePayment = (e) => {
            bankCode.value = "";
            current_pay_method.data = {};
            let obj = {};
            if (e == 0) {
                obj = {
                    payMethod: "B2B",
                    payMethodName: "网银支付",
                    payType: "B2B"
                };
            } else if (e == 1) {
                obj = {
                    payMethod: "ACCEPT",
                    payMethodName: "承兑支付",
                    payType: "ACCEPT"
                };
            } else if (e == 3) {
                obj = {
                    payMethod: "OFFLINE",
                    payMethodName: "线下结算",
                    payType: "OFFLINE"
                };
            } else {
                obj = {
                    payMethod: "OderPay",
                    payMethodName: "订单支付",
                    payType: "ACCEPT"
                };
            }
            current_pay_method.data = obj;
            // return console.log(current_pay_method.data)
            e == 1 ? (isChengdui.value = true) : (isChengdui.value = false);
            activeIndex.value = e;
        };

        //初始化支付方式
        const initPayment = () => {
            console.log(pay_info.data, 890);
            console.log(payData.data, 7090);
            if (pay_info.data.paymentWays.includes("E-BANK") && payData.data.paymentWays.includes("E-BANK")) {
                paymentList.data[0].disabled = true;
                // return ElMessage.warning('当前订单不支持网银支付！')
            }
            if (pay_info.data.paymentWays.includes("ACCEPT") && payData.data.paymentWays.includes("ACCEPT")) {
                paymentList.data[1].disabled = true;
                // return ElMessage.warning('当前订单不支持承兑支付！')
            }

            if (pay_info.data.paymentWays.includes("OFFLINE") && payData.data.paymentWays.includes("OFFLINE")) {
                paymentList.data[3].disabled = true;
            }

            //// paymentList.data[3].disabled = true;
            //  console.log( payData.data.paymentWays.includes("OFFLINE"),6666666);
            if (true) {
                paymentList.data[2].disabled = false;
                // return ElMessage.warning('当前订单不支订单支付！')
            }
        };

        //获取支付方式
        const getPayMethod = () => {
            handlePayment(0);
            let data = [
                {
                    payMethod: "B2B",
                    payMethodName: "B2B",
                    payType: "B2B"
                },
                {
                    payMethod: "balance",
                    payMethodName: "余额支付",
                    payType: "BALANCE"
                }
            ];
            hasPayMethod.value = data.length > 0 ? true : false;
            data.map((item) => {
                if (item.payMethod == "balance") {
                    balance_list.data.push(item);
                } else {
                    other_pay_list.data.push(item);
                }
            });
            //proxy
            //  .$get("v3/business/front/orderPay/payMethod", {
            //    source: "pc",
            //    type: 1,
            //  })
            //  .then((res) => {
            //    if (res.state == 200) {
            //      hasPayMethod.value = res.data.length > 0 ? true : false;
            //      res.data.map((item) => {
            //        if (item.payMethod == "balance") {
            //          balance_list.data.push(item);
            //        } else {
            //          other_pay_list.data.push(item);
            //        }
            //      });
            //    } else {
            //      ElMessage(res.msg);
            //    }
            //  })
            //  .catch(() => {
            //    //异常处理
            //  });
        };

        //** 查询会员拥有的支付方式和付款方式 */
        const getPayWays = () => {
            let params = { memberId: store.state.memberInfo.memberId };
            proxy.$get("api/shopp/member/order/member/pay/ways", params).then((res) => {
                if (res.code == 200) {
                    payData.data = res.data;
                }
            });
        };

        //获取订单支付数据
        const getPayInfo = () => {
            console.log(route.query.paySn);
            getPayWays();
            let params = {};
            params.source = route.query.payFrom;
            if (typeof route.query.paySn == "string") {
                params.payNumbers = route.query.paySn;
            } else {
                params.payNumbers = route.query.paySn.join();
            }
            proxy
                .$get("api/shopp/member/order/pay/detail", params, "json")
                .then((res) => {
                    //原接口：v3/business/front/orderPay/payInfo
                    if (res.code == 200) {
                        pay_info.data = res.data;
                        buy_name_list.value = res.data.goodsDetails?.map((item) => item.goodsNameJoin).join("，");
                        initPayment();
                    } else if (res.code == 267) {
                        ElMessage.success(res.msg + ",2s后自动跳转订单列表");
                        //清除定时器
                        if (timer.value) {
                            clearInterval(timer.value);
                            timer.value = 0;
                        }
                        setTimeout(() => {
                            router.replace({
                                path: "/member/order/list"
                            });
                        }, 2000);
                    } else {
                        ElMessage(res.msg);
                    }
                })
                .catch(() => {
                    //异常处理
                });
        };
        const changePayMethod = (pay_method) => {
            console.log(pay_method);
            if (pay_method == "yhk") {
                let obj = {
                    payMethod: "yhk",
                    payMethodName: "银行卡支付",
                    payType: "YHK"
                };
                current_pay_method.data = obj;
            } else {
                ischecked.value = "";
                bankCode.value = "";
                current_pay_method.data = pay_method;
                isChengdui.value = false;
                activeIndex.value = null;
            }
        };
        // 获取银行卡列表
        const bankListSelf = ref([]);
        const getBankInfo = async () => {
            console.log(7878777);
            proxy.$get("api/shopp/member/bank/card/list").then((res) => {
                if (res.code == 200) {
                    // ElMessage.success(res.msg)
                    // console.log(res)
                    bankListSelf.value = res.data;
                } else {
                    ElMessage.warning(res.msg);
                }
            });
        };
        getBankInfo();
        // 协议支付绑卡
        // 获取验证码
        const descNum = ref(0);
        const descNum1 = ref(0);
        let isFlag = ref(false);
        const getTestNum = () => {
            if (isFlag.value == true) {
                return;
            }
            if (descNum.value) {
                return;
            }
            isFlag.value = true;
            // const timedesc = setInterval(() => {
            //     descNum.value -= 1
            // }, 1000);
            // setTimeout(() => {
            //     isFlag.value = false
            //     descNum.value = 60
            //     clearInterval(timedesc)
            // }, 6000)

            // let params = {}
            let params = {};
            params = bankForm.value;
            // params.
            // proxy.$post('/v3/member/front/balanceRecharge/sendSMS', params).then(res => {
            proxy.$post("/v3/member/front/balanceRecharge/sendSMS", JSON.stringify(params), "json").then((res) => {
                if (res.state == 200) {
                    ElMessage.success(res.msg);
                    descNum.value = 120;
                    countDown();
                    isFlag.value = false;
                } else {
                    ElMessage.warning(res.msg);
                    isFlag.value = false;
                }
            });
        };
        const getTestNums = (item) => {
            if (isFlag.value == true) {
                return;
            }
            if (descNum.value) {
                return;
            }
            isFlag.value = true;
            // const timedesc = setInterval(() => {
            //     descNum.value -= 1
            // }, 1000);
            // setTimeout(() => {
            //     isFlag.value = false
            //     descNum.value = 60
            //     clearInterval(timedesc)
            // }, 6000)

            let params = {};
            params.bankCardId = item.id;
            proxy.$post("api/pay/pingan/bank/agreePaySignatureSendSMS", params, "json").then((res) => {
                if (res.code == 200) {
                    ElMessage.success(res.msg);
                    descNum.value = 120;
                    countDown();
                    isFlag.value = false;
                } else {
                    ElMessage.warning(res.msg);
                    isFlag.value = false;
                }
            });
        };
        const timeOutId = ref(""); //定时器的返回值
        const timeOutId1 = ref(""); //定时器的返回值
        const isFlag1 = ref(false);
        //倒计时
        const countDown = () => {
            descNum.value--;
            if (descNum.value == 0) {
                isFlag.value = false;
                clearTimeout(timeOutId.value);
            } else {
                timeOutId.value = setTimeout(countDown, 1000);
            }
        };

        //倒计时
        const countDown1 = () => {
            descNum1.value--;
            if (descNum1.value == 0) {
                isFlag1.value = false;
                clearTimeout(timeOutId1.value);
            } else {
                timeOutId.value = setTimeout(countDown1, 1000);
            }
        };
        // 激活
        const bankMessage = ref();
        const activationCard = () => {
            if (bankinfo.data.isBind == 2) {
                if (!bankMessage.value) {
                    ElMessage.warning("请输入验证码");
                    return;
                }
                let params = {};
                //params.code = bankMessage.value;
                params.bankCardId = bankFlag.value;
                params.card = {
                    uscUserId: store.state.memberInfo.uscUserId,
                    mobileCode: bankMessage.value
                };
                //if(){

                //}
                proxy.$post("api/pay/pingan/bank/agreePaySignature", params, "json").then((res) => {
                    if (res.code == 200) {
                        // descNum.value = 60
                        isgoBnak.value = 4;
                        ElMessage.success(res.msg);
                    } else {
                        ElMessage.warning(res.msg);
                    }
                });
            } else {
                if (!bankFlag.value) {
                    return ElMessage.warning("请选择银行卡！");
                }
                isgoBnak.value = 4;
            }
        };
        // 提交
        const bindCard = () => {
            let param = {};
            param = bankForm.value;
            param.messageCheckCode = messageCheckCode.value;
            // param =
            // param.cardId = 111111
            proxy
                .$post("/v3/member/front/balanceRecharge/agreePaySignature", JSON.stringify(param), "json")
                .then((res) => {
                    // proxy.$post('/v3/member/front/balanceRecharge/agreePaySignature', param).then(res => {
                    if (res.state == 200) {
                        ElMessage.success(res.msg);
                        getBankInfos();
                    } else {
                        ElMessage.warning(res.msg);
                    }
                });
        };
        const getBankInfos = async () => {
            proxy.$get("api/shopp/member/bank/card/list").then((res) => {
                if (res.code == 200) {
                    descNum.value = 0;
                    clearTimeout(timeOutId.value);
                    isFlag.value = false;
                    bankFlag.value = res.data[0].id;
                    isgoBnak.value = 4;
                } else {
                    ElMessage.warning(res.msg);
                }
            });
        };
        // 选择银行卡
        let bankFlag = ref();
        const bankinfo = reactive({ data: {} });
        const checkBank = (item) => {
            bankFlag.value = item.id;
            bankinfo.data = item;
        };
        //去支付
        const payComplete = ref(false);
        const payFlag = ref(true);

        const pay = () => {
            console.log(memberInfo.value);
            console.log(current_pay_method.data);
            let param = {};
            if (memberInfo.value.memberUserType == 0) {
                if (!current_pay_method.data.payMethod) {
                    ElMessage.warning("请选择支付方式");
                    return;
                }
            }
            if (!memberInfo.value.hasPayPassword) {
                ElMessage.warning("请设置支付密码");
                hasFlag.value = true;
                return;
            }
            if (current_pay_method.data.payMethod == "yhk") {
                if (!memberInfo.value.hasPayPassword) {
                    ElMessage.warning("请设置支付密码");
                    hasFlag.value = true;
                    return;
                }
                if (memberInfo.value.memberUserType != 1) {
                    if (bankListSelf.value.length > 0) {
                        isgoBnak.value = 2;
                        return;
                    } else {
                        isgoBnak.value = 3;
                        return;
                    }
                }
            } else if (current_pay_method.data.payMethod == "balance") {
                //余额支付
                if (password.value == "") {
                    ElMessage.warning("请输入支付密码");
                    return;
                }
                param.payPwd = Encrypt(password.value); //支付密码,使用余额时必传
            } else if (current_pay_method.data.payMethod == "B2B") {
                console.log("进来");
                if (!bankCode.value) {
                    ElMessage.warning("请选择银行卡支付");
                    return;
                }
                // if (!bankNum.value) {
                //     ElMessage.warning("请输入银行卡号");
                //     return
                // }
                // 企业支付

                param.payType = "B2B";
                param.payMethod = "E-BANK";
                param.paySn = pay_info.data.paySn;
                param.bankCode = bankCode.value;
                param.bankName = bankName.value;
                // param.bankCardNo= '';
            }

            if (!bankCode.value) {
                console.log("没有bankCode");
                param.payType = current_pay_method.data.payType;
                param.payMethod = current_pay_method.data.payMethod;
            }
            param.paySn = pay_info.data.paySn;
            //余额支付
            if (param.payMethod == "balance") {
                if (password.value == "") {
                    ElMessage.warning("请输入支付密码");
                    return;
                }
                param.payPwd = Encrypt(password.value); //支付密码,使用余额时必传
            }
            // 支付单号
            if (typeof route.query.paySn == "string") {
                param.paymentNumbers = route.query.paySn.split(",");
            } else {
                param.paymentNumbers = route.query.paySn;
            }

            // 承兑支付
            if (activeIndex.value == 1) {
                // for (let i = 0; i < cdArr.data.length; i++) {
                //     if(!cdArr.data[i].billAmount || !cdArr.data[i].billCode) {
                //         return ElMessage.warning("请先完善凭证信息！");
                //     }
                // }
                param.bills = cdArr.data;
                param.payType = current_pay_method.data.payType;
                param.payMethod = current_pay_method.data.payMethod;
            }

            // 判断是否可支付
            if (!payFlag.value) {
                return false;
            }
            payFlag.value = false;
            //console.log(cdArr.data[0].billCode,909090);
            // return console.log(param)
            //请求支付接口
            if (route.query.payFrom == 2) {
                let result = {
                    periodCode: route.query.paySn,
                    payMethod: param.payMethod,
                    bankCode: param.bankCode,
                    bankName: param.bankName,
                    memberCardId: param.memberCardId,
                    smsCode: param.smsCode,
                    payPwd: param.payPwd,
                    acceptPayRecordReqList: Number(cdArr.data[0].billCode) == 0 ? [] : param.bills
                };

                proxy
                    .$post("api/shopp/accountPeriod/pay", result, "json")
                    .then((res) => {
                        //原接口：v3/business/front/orderPay/doPay
                        if (res.code == 200) {
                            console.log(param.payMethod);
                            // return console.log(res)
                            if (param.payMethod == "E-BANK") {
                                document.write(res.data.redirect);
                            }
                            if (param.payMethod == "balance" || param.payMethod == "ACCEPT") {
                                ElMessage.success("支付成功,2s后自动跳转账期结算列表");
                                chengduiVisible.value = false;
                                payComplete.value = true;
                                //清除定时器
                                setTimeout(() => {
                                    router.replace({
                                        path: "/member/payment/Settlement"
                                    });
                                }, 2000);
                            } else if (param.payMethod == "alipay") {
                                document.write(res.data.payData); //自动提交表单数据
                            } else if (param.payMethod == "wx") {
                                show_wx_pay.value = true;
                                wxPayQrImg.value = "data:image/png;base64," + res.data.payData; //微信支付二维码
                                // 定时查询是否支付成功
                                timer.value = setInterval(() => {
                                    queryPayState();
                                }, 3000);
                                //两小时自动跳转订单列表
                                setTimeout(() => {
                                    router.replace({
                                        path: "/member/order/list"
                                    });
                                }, 432000000);
                            }
                        } else {
                            payFlag.value = true;
                            ElMessage(res.msg);
                        }
                    })
                    .catch(() => {
                        payFlag.value = true;
                        //异常处理
                    });
            } else {
                proxy
                    .$post("api/shopp/member/order/doPay", param, "json")
                    .then((res) => {
                        //原接口：v3/business/front/orderPay/doPay
                        if (res.code == 200) {
                            console.log(param.payMethod);
                            // return console.log(res)
                            if (param.payMethod == "E-BANK") {
                                document.write(res.data.redirect);
                            }
                            if (
                                param.payMethod == "balance" ||
                                param.payMethod == "ACCEPT" ||
                                param.payMethod == "OFFLINE"
                            ) {
                                chengduiVisible.value = false;
                                ElMessage.success("支付成功,2s后自动跳转订单列表");
                                payComplete.value = true;
                                //清除定时器
                                setTimeout(() => {
                                    router.replace({
                                        path: "/member/order/list"
                                    });
                                }, 2000);
                            } else if (param.payMethod == "alipay") {
                                document.write(res.data.payData); //自动提交表单数据
                            } else if (param.payMethod == "wx") {
                                show_wx_pay.value = true;
                                wxPayQrImg.value = "data:image/png;base64," + res.data.payData; //微信支付二维码
                                // 定时查询是否支付成功
                                timer.value = setInterval(() => {
                                    queryPayState();
                                }, 3000);
                                //两小时自动跳转订单列表
                                setTimeout(() => {
                                    router.replace({
                                        path: "/member/order/list"
                                    });
                                }, 432000000);
                            } else {
                                ElMessage.success("支付成功,2s后自动跳转订单列表");
                                setTimeout(() => {
                                    router.replace({
                                        path: "/member/order/list"
                                    });
                                }, 2000);
                            }
                        } else {
                            payFlag.value = true;
                            ElMessage(res.msg);
                        }
                    })
                    .catch(() => {
                        payFlag.value = true;
                        //异常处理
                    });
            }
            //  proxy
            //    .$post("api/shopp/member/order/doPay", param, "json")
            //    .then((res) => {
            //      //原接口：v3/business/front/orderPay/doPay
            //      if (res.code == 200) {
            //        console.log(param.payMethod);
            //        // return console.log(res)
            //        if (param.payMethod == "E-BANK") {
            //          document.write(res.data.redirect);
            //        }
            //        if (param.payMethod == "balance" || param.payMethod == "ACCEPT") {
            //          ElMessage.success("支付成功,2s后自动跳转订单列表");
            //          payComplete.value = true;
            //          //清除定时器
            //          setTimeout(() => {
            //            router.replace({
            //              path: "/member/order/list",
            //            });
            //          }, 2000);
            //        } else if (param.payMethod == "alipay") {
            //          document.write(res.data.payData); //自动提交表单数据
            //        } else if (param.payMethod == "wx") {
            //          show_wx_pay.value = true;
            //          wxPayQrImg.value = "data:image/png;base64," + res.data.payData; //微信支付二维码
            //          // 定时查询是否支付成功
            //          timer.value = setInterval(() => {
            //            queryPayState();
            //          }, 3000);
            //          //两小时自动跳转订单列表
            //          setTimeout(() => {
            //            router.replace({
            //              path: "/member/order/list",
            //            });
            //          }, 432000000);
            //        }
            //      } else {
            //        payFlag.value = true;
            //        ElMessage(res.msg);
            //      }
            //    })
            //    .catch(() => {
            //      payFlag.value = true;
            //      //异常处理
            //    });
        };
        // 定时查询是否支付成功
        const queryPayState = () => {
            getPayInfo();
        };
        //隐藏微信支付内容
        const hideWxPay = () => {
            show_wx_pay.value = false;
        };
        const hideWxPays = () => {
            isgoBnak.value = null;
        };
        //刷新微信支付二维码
        const refreshWxQrcode = () => {
            pay();
        };
        //收起展开详情
        const showDetail = () => {
            show_detail.value = !show_detail.value;
        };
        //承兑支付方式
        const handleChengdui = () => {
            chengduiVisible.value = true;
        };
        //承兑新增凭证
        const handleAddCd = () => {
            let item = { billCode: null, billAmount: null };
            cdArr.data.push(item);
        };
        //承兑删除凭证
        const handleDelCd = (index) => {
            if (cdArr.data.length == 1) {
                return ElMessage.warning("至少保留一张凭证");
            }
            cdArr.data.splice(index, 1);
        };

        onMounted(() => {
            paySn.value = route.query.paySn;
            // ifcart.value = route.query.ifcart;
            getPayMethod();
            getPayInfo();
        });
        const goRecharge = () => {
            router.push("/member/recharge");
        };
        const forgetPassword = () => {
            router.push("/member/pwd/reset");
        };
        onUnmounted(() => {
            if (timer.value) {
                clearInterval(timer.value);
                timer.value = 0;
            }
        });
        // 获取银行列表
        const bankNum = ref(); //银行卡号
        let bankList = ref([]);
        const getBankList = () => {
            proxy.$get("api/pay/common/queryBankB2b").then((res) => {
                if (res.code == 200) {
                    // ElMessage.success(res.msg)
                    res.data.forEach((item) => {
                        item.img = require(`../../assets/bank/${item.code}.png`);
                    });
                    bankList.value = res.data;
                } else {
                    ElMessage.warning(res.msg);
                }
            });
        };
        getBankList();
        const ischecked = ref("");
        const bankCode = ref(null); //bankcode
        const changeBnak = (item) => {
            current_pay_method.data = {};
            handlePayment(0);
            ischecked.value = item.code;
            bankCode.value = item.code;
            bankName.value = item.name;
        };
        // 选择银行卡后支付 个人
        let payPwd = ref("");
        const getTestNumss = (item) => {
            if (!payPwd.value) {
                ElMessage.success("请输入支付密码");
                return;
            }
            if (isFlag1.value == true) {
                return;
            }
            if (descNum1.value) {
                return;
            }
            isFlag1.value = true;
            // const timedesc = setInterval(() => {
            //     descNum.value -= 1
            // }, 1000);
            // setTimeout(() => {
            //     isFlag.value = false
            //     descNum.value = 60
            //     clearInterval(timedesc)
            // }, 6000)

            let param = {};
            // param.paySn = paySn.value
            param.memberCardId = bankFlag.value;
            // param.password = payPwd.value
            param.paymentNumbers = Array.isArray(paySn.value) ? paySn.value : paySn.value.split(",");
            param.memberId = store.state.memberInfo.memberId;
            proxy.$post("api/shopp/member/order/do/send/sms", param, "json").then((res) => {
                //原接口：v3/member/front/balanceRecharge/sendShortMessage
                if (res.code == 200) {
                    ElMessage.success(res.msg);
                    descNum1.value = 60;
                    countDown1();
                    isFlag1.value = false;
                } else {
                    ElMessage.warning(res.msg);
                    isFlag1.value = false;
                }
            });
        };
        const testnum = ref(null);
        const payFlag1 = ref(true);
        const payNow = () => {
            if (!testnum.value) {
                return ElMessage.warning("请输入验证码");
            }
            if (!payPwd.value) {
                return ElMessage.warning("请输入支付密码");
            }
            let param = {};
            param.paymentNumbers = Array.isArray(paySn.value) ? paySn.value : paySn.value.split(",");
            param.memberCardId = bankFlag.value;
            param.payPwd = Encrypt(payPwd.value);
            param.smsCode = testnum.value;
            param.payMethod = "BANK";
            if (!payFlag1.value) {
                return;
            }
            proxy.$post("api/shopp/member/order/doPay", param, "json").then((res) => {
                //原接口：v3/member/front/balanceRecharge/pay
                payFlag1.value = false;
                if (res.code == 200) {
                    // ElMessage.success(res.msg)
                    ElMessage.success("支付成功,2s后自动跳转订单列表");
                    payComplete.value = true;
                    //清除定时器
                    setTimeout(() => {
                        router.replace({
                            path: "/member/order/list"
                        });
                    }, 2000);
                } else {
                    payFlag1.value = true;
                    ElMessage.warning(res.msg);
                }
            });
        };
        const PaymentAccount = () => {
            proxy.$post("api/shopp/accountPeriod/pay", param, "json").then((res) => {
                //原接口：v3/member/front/balanceRecharge/sendShortMessage
                if (res.code == 200) {
                    ElMessage.success(res.msg);
                    descNum1.value = 60;
                    countDown1();
                    isFlag1.value = false;
                } else {
                    ElMessage.warning(res.msg);
                    isFlag1.value = false;
                }
            });
        };

        onMounted(() => {
            bankForm.value.payerReserverMobile = store.state.memberInfo.memberMobile;
        });
        return {
            ifcart,
            pay_method,
            balance,
            otherPay,
            ali,
            wechat,
            pay_info,
            current_pay_method,
            balance_list,
            other_pay_list,
            changePayMethod,
            buy_name_list,
            password,
            pay,
            handleChengdui,
            countDown,
            wx_pay_tip,
            show_wx_pay,
            hideWxPay,
            refreshWxQrcode,
            wxPayQrImg,
            goRecharge,
            forgetPassword,
            showDetail,
            show_detail,
            hasPayMethod,
            configInfo,
            timeOutId,
            tipImg,
            defaultImg,
            memberInfo,
            payComplete,
            bankForm, //调试用的表单字段,
            bankList,
            getBankInfos,
            changeBnak,
            ischecked,
            bankNum,
            bankCode,
            bankListSelf,
            isgoBnak,
            getTestNum,
            isFlag,
            descNum,
            messageCheckCode,
            bindCard,
            checkBank,
            bankFlag,
            getTestNums,
            activationCard,
            payNow,
            payPwd,
            getTestNumss,
            testnum,
            bankMessage,
            bankinfo,
            hideWxPays,
            descNum1,
            countDown1,
            isFlag1,
            timeOutId1,
            hasFlag,
            bankName,
            payFlag,
            payFlag1,
            chengduiVisible,
            isChengdui,
            cdArr,
            paymentList,
            activeIndex,
            not_select,
            selected,
            firmName,
            bankNameVal,
            paymentAccount,
            firmAccountVal,
            options,
            payData,
            initPayment,
            handlePayment,
            handleAddCd,
            handleDelCd,
            route
        };
    }
};
</script>
<!-- 签署协议表单样式 -->
<style lang="scss">
.bank_bottom_Form {
    // .el-cascader {
    //     width: 100%;
    // }

    .el-input__suffix {
        top: -4px;
    }

    .el-input__inner {
        height: 31px;
    }

    .el-form-item {
        margin-bottom: 3px;

        .el-select {
            width: 100%;
        }
    }
}
</style>
<style scoped lang="scss">
#pay {
    .itemInput {
        width: 318px;
        height: 42px;
        border: 1px solid #dcdfe6;
        border-radius: 4px;
        padding-left: 20px;
    }
    .timeOut2 {
        // bottom: 0px;
        // left: 330px;
        // right: 30px;
        width: 100px;
        height: 42px;
        border-radius: 4px;
        background-color: $colorMain;
        color: rgba(255, 255, 255, 1);
        font-size: 14px;
        text-align: center;
        line-height: 42px;
        margin-left: -100px;
        cursor: pointer;
    }
}

.bankInput {
    position: absolute;
    // height: 30px;
    display: block;
    bottom: -50px;
    left: 100px;

    span {
        margin-right: 10px;
        font-size: 14px;
    }

    .bankInput_con {
        height: 30px;
    }
}

.bankInputs {
    position: absolute;
    // height: 30px;
    display: block;
    bottom: -85px;
    left: 100px;
}

.bankList {
    width: 1200px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border: 2px solid #e1e1e2;
    padding-top: 45px;
    padding-bottom: 27px;
    .borderClass {
        border: 1px solid $colorMain2 !important;
    }
}

.chengdui_box {
    width: 457px;
    background-color: #f8f8f8;
    border: 1px solid #eff0f1;
    padding: 28px 22px;
    .chengdui_title {
        font-size: 15px;
        font-weight: bold;
        color: #000000;
    }
    .tx,
    .tx2 {
        color: #000000;
        font-size: 15px;
    }
    .tx {
        margin-top: 30px;
    }
    .tx2 {
        margin-top: 20px;
    }
}
.order_box {
    .tips {
        width: 457px;
        line-height: 19px;
        padding: 15px 25px;
        color: #ff0000;
        background: #fff2f1;
        border: 1px solid #ffe5e5;
    }
    .orderPay_info {
        margin-top: 30px;
        div {
            margin-top: 10px;
        }
        .tx {
            display: inline-block;
            width: 100px;
        }
        .el-input {
            width: 356px;
        }
    }
}
.activation {
    width: 170px;
    height: 40px;
    line-height: 40px;
    border-radius: 4px;
    background-color: $colorMain;
    color: rgba(255, 255, 255, 1);
    font-size: 14px;
    text-align: center;
    font-family: Microsoft Yahei;
    margin-top: 85px;
}

.card_list {
    display: flex;
    // justify-content: space-evenly;
    flex-wrap: wrap;
    margin-top: 10px;

    .item {
        position: relative;
        margin-right: 20px;
        margin-top: 30px;
    }

    .unbinding {
        position: absolute;
        top: 11px;
        right: 17px;
        width: 15px;
        height: 15px;
        // background-color: red;
        border-radius: 50%;
        background-image: url(../../assets/store_select.png);
        background-size: cover;
    }
    .unbinding2 {
        position: absolute;
        top: 11px;
        right: 17px;
        width: 15px;
        height: 15px;
        // background-color: red;
        border-radius: 50%;
        background-image: url(../../assets/store_not_select.png);
        background-size: cover;
    }
}

.bank_content {
    margin-left: 20px;

    .bank_content_top {
        height: 50px;
        line-height: 50px;
        color: #1c1c1c;
        font-size: 14px;
        font-weight: 600;
        text-align: left;
    }

    .bank_content_bottom {
        width: 418px;
        height: 169px;
        background: linear-gradient(0deg, #47509a, #6771e6);
        box-shadow: 0px 0px 68px 0px rgba(14, 49, 119, 0);
        border-radius: 14px;
        color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        cursor: pointer;
        div {
            color: rgba(255, 255, 255, 1);
            font-size: 18px;
            font-family: SourceHanSansSC-bold;
            font-weight: 600;
            margin-left: 20px;

            &:nth-child(2) {
                margin-top: -20px;
            }
        }
    }
}

.bank_bottom_Form {
    padding-left: 10px;
    position: relative;
    .timeOut {
        position: absolute;
        top: 5px;
        left: 186px;
        width: 109px;
        height: 30px;
        border-radius: 0 4px 4px 0;
        background-color: $colorMain;
        color: rgba(255, 255, 255, 1);
        font-size: 14px;
        text-align: center;
        line-height: 30px;
    }

    .next {
        width: 170px;
        background-color: $colorMain;
        height: 40px;
        border-radius: 5px;
        text-align: center;
        line-height: 40px;
        color: #fff;
        cursor: pointer;
        margin-left: 128px;
        margin-top: 40px;
    }
}
</style>
<style lang="scss">
@import "../../style/pay.scss";
@import "../../style/base.scss";

.no_payMethod {
    margin: 0 auto;
    width: 1200px;
    height: 560px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    img {
        width: 163px;
        height: 114px;
    }

    p {
        margin-top: 39px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
    }
}
.accountPayment {
    margin-bottom: 25px;
    margin-top: 1px !important;
    p {
        font-weight: bold;
    }
}
</style>
